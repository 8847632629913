/**
 * AutoSaveForm util
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import React, { FormEvent, ReactElement, ReactNode, useCallback, useEffect } from 'react';

import { FormProps } from 'antd';

import { Form } from 'formik';

import { FormikHelper, If } from '@/cutils';
import { ApolloError } from '@apollo/client/errors';

import { ConfigStore } from '@/store/core/config';


interface Props extends Omit<FormProps, 'onChange'> {
    /** Children component */
    children: ReactElement | ReactNode;
    /** Handle changes of form */
    onChange?: (e?: FormEvent<HTMLFormElement>) => void;
    /** Handle submit of form (triggers only on "submit" button click) */
    onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
    /** Handle instant changes (every change trigger this fn) */
    onInstantChange?: (e?: FormEvent<HTMLFormElement>) => void;
    /** Delay for debouncing */
    delay?: number;
    /** Error on a save form */
    error?: ApolloError | undefined;
    /** Ignore non-named fields of form */
    onlyNamedFields?: boolean;
    /** Save draft (triggers on ValuesObserver changes) */
    handleSaveDraft?: (values: Record<any, any>) => void;
    /** Save on unloading */
    safeBeforeUnload?: boolean;
    /** Ignore auto save */
    disabled?: boolean;
}


const AutoSaveForm = (props: Props) => {

    const {
        error,
        children,
        onChange,
        onSubmit,
        onInstantChange,
        onlyNamedFields,
        handleSaveDraft,
        delay = 950,
        disabled = false,
        safeBeforeUnload = false,
        ...rest
    } = props;

    const debouncedCallback = useCallback(
        _.debounce((callback) => callback(), error ? 5000 : delay),
        [],
    );

    const onUnload = () => {
        onChange?.();
    };

    const handleChange = (e?: FormEvent<HTMLFormElement>) => {
        const targetName = (e?.target as HTMLFormElement)?.name;

        if (onlyNamedFields && (!targetName || targetName === '_ignore')) {
            return;
        }

        if (!!ConfigStore.interaction.lastClickAt
            && (moment().diff(moment(ConfigStore.interaction.pageLoadedAt), 'seconds') > 0)
            && !(e?.target as any)?.classList?.contains('ignore-instant-change')
            && !(e?.target as any)?.parentNode?.classList?.contains('ignore-instant-change')
        ) {
            onInstantChange?.(e);
        }

        return debouncedCallback(() => onChange?.(e));
    };

    useEffect(() => {
        error && handleChange();

        if (safeBeforeUnload) {
            window.addEventListener('beforeunload', onUnload);
        }

        return () => {
            if (safeBeforeUnload) {
                window.removeEventListener('beforeunload', onUnload);
            }
        };
    }, [ error ]);

    return (
        <Form onChange={(e) => !disabled && handleChange(e)} onSubmit={(e) => {
            e.preventDefault();

            onSubmit?.(e);
        }} {...rest}>
            {children}

            <If is={_.isFunction(handleSaveDraft)}>
                <FormikHelper.ValuesObserver onChange={(values) => handleSaveDraft?.(values)}/>
            </If>
        </Form>
    );
};


export { AutoSaveForm };

