/**
 * ManageCourseDiscountModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useStableModalParams } from '@/router/index';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Title } from '@exode.ru/vkui';
import { Graphql, If, Modal } from '@/cutils';
import { DiscountManageFindOneQuery } from '@/codegen/graphql';

import { DiscountFormView } from '@/pages/Manage/Courses/Discounts/views/DiscountFormView';


interface Props {
    id: string;
}


const ManageCourseDiscountModal = (props: Props) => {

    const { t } = useI18n('modals.Course.Discount');

    const [ { discountId } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id}
                    className="modal:w-550"
                    subheader={t('promoCodeCanBeUsedOnlyForCurrentCourse')}
                    header={(
                        <Title className="modal-title" level="2" weight="3">
                            {!!discountId ? t('editingPromoCode') : t('creatingPromoCode')}
                        </Title>
                    )}>
            <Modal.Body fullHeight isScrollable>
                <If is={!discountId}>
                    <DiscountFormView/>
                </If>

                <If is={!!discountId}>
                    <DiscountManageFindOneQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <Page.Spinner/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ discountManageFindOne: discount }) => (
                                    <DiscountFormView discount={discount}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}/>
                        </>
                    )} variables={{ discountId: +discountId }}/>
                </If>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageCourseDiscountModal };
