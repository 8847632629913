/**
 * PromotionRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';

import { Storage } from '@/api/storage';
import { lazyWithRetry } from '@/helpers/react';


const PromotionBonusPage = lazyWithRetry(() => import('@/pages/Promotion/Bonus'));
const PromotionInvitePage = lazyWithRetry(() => import('@/pages/Promotion/Invite'));


const PromotionRoutes: RouterItemsType = {
    '/promo/bonus': {
        view: <PromotionBonusPage/>,
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/referral/:referralId([0-9_A-Za-z-_]+)': {
        view: <PromotionInvitePage/>,
        type: 'iframe',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
        onGuardForbidden: ({ params }) => {
            Storage.set('user:referral-uuid', params.referralId);
        },
    },
};


export { PromotionRoutes };
