/**
 * PageTabHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Portal } from '@/cutils';

import { PageStore as PageCoreStore } from '@/store/core/page';

import { PanelHeader } from '@exode.ru/vkui';


const PageTabHeaderPart = () => {
    return (
        <PanelHeader separator={false}
                     {..._.omit(PageCoreStore.panelHeader, [ 'fadein' ])}
                     before={<Portal id="panel-header-page:left" renderer/>}
                     after={<Portal id="panel-header-page:right" renderer/>}>
            <Portal id="panel-header-page:content" renderer/>
            <Portal id="panel-header-page:context" renderer/>
        </PanelHeader>
    );
};


export { PageTabHeaderPart };
