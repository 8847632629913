/**
 * UseSchoolUser
 *
 * @author: exode <hello@exode.ru>
 */

import { GqlResult } from '@/types/graphql';

import {
    CreateAccessManyProductInput,
    CreateManyUserInput,
    SchoolUserManageCreateManyMutationResult,
    useSchoolUserManageCreateManyMutation,
} from '@/codegen/graphql';


export const useSchoolUser = () => {

    const initialValuesForCreate = {
        users: {
            accounts: [
                {
                    email: '',
                    phone: '',
                    type: 'email' as 'email' | 'phone',
                },
            ],
        },
        enrollments: {
            accesses: [] as {
                productId: number;
                launchId: number;
                priceId: number;
            }[],
        },
    };

    const [ _schoolUserManageCreate, {
        data: schoolUserManageCreateData,
        loading: schoolUserManageCreateLoading,
    } ] = useSchoolUserManageCreateManyMutation();

    const schoolUserManageCreate = (
        users: CreateManyUserInput,
        enrollments: CreateAccessManyProductInput,
        onCompleted?: (
            data: GqlResult<SchoolUserManageCreateManyMutationResult>['schoolUserManageCreateMany'],
        ) => void,
    ) => {
        return _schoolUserManageCreate({
            variables: { users, enrollments },
            onCompleted: (data) => {
                onCompleted?.(data.schoolUserManageCreateMany);
            },
        });
    };

    return {
        initialValuesForCreate,
        schoolUserManageCreate,
        schoolUserManageCreateData,
        schoolUserManageCreateLoading,
    };
};
