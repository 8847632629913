/**
 * ChatCellItem
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactNode, useEffect } from 'react';

import { observer, SubscriptionStore } from '@/store/subscription/subscription';

import { ChatFindManyQueryResult, ChatMessageStatus, ChatType } from '@/codegen/graphql';

import { GqlResult } from '@/types/graphql';
import { If, SvgComponent } from '@/cutils';
import { ChatService } from '@/services/Chat/Chat';
import { chatMessageStatusIcon } from '@/types/chat';
import { ProfilePartial, User } from '@/components/Atoms/User';

import { Caption, RichCell } from '@exode.ru/vkui';
import { Icon16CheckDoubleOutline, Icon20BlockOutline, Icon20LockOutline, Icon24MuteOutline } from '@vkontakte/icons';

import { ChatCellContainer } from './styled';

import { ChatCellAvatarPart } from '../parts/ChatCellAvatarPart';
import { ChatCellCaptionPart } from '../parts/ChatCellCaptionPart';


export interface ChatCellItemProps {
    isActive: boolean;
    chat: NonNullable<GqlResult<ChatFindManyQueryResult>['chatFindMany']['items']>[number];
    onChatCellClick: (chatId: number) => void;
    isRounded?: boolean;
    lastActionText?: ReactNode;
    lastMessageText?: ReactNode;
}


const ChatCellItem = observer((props: ChatCellItemProps) => {

    const {
        chat,
        isActive,
        onChatCellClick,
        lastActionText,
        isRounded = false,
    } = props;

    const { id, name, lastMessage, myMember } = chat;

    const myMemberIsBlocked = chat?.myMember?.blocked;
    const companionMemberIsBlocked = chat?.personalCompanionMember?.blocked;

    const handleChatCellClick = () => {
        onChatCellClick(id);
    };

    useEffect(() => {
        SubscriptionStore.subscribeToChatTypingIds(chat?.id);
    }, [ chat?.id ]);

    return (
        <ChatCellContainer key={id} onClick={handleChatCellClick}>
            <RichCell hasActive={false}
                      data-test="chat.cell-item"
                      before={<ChatCellAvatarPart {...props}/>}
                      caption={<ChatCellCaptionPart {...props}/>}
                      className={[
                          'px-2 py-[10px] min-h-[75px]',
                          isActive ? 'bg-selected' : '',
                          !isRounded ? '!rounded-none' : '',
                      ].join(' ')}>
                <div className="flex justify-between items-baseline">
                    <div className="flex items-center max-w-[145px] min-w-[calc(100%_-_95px)]">
                        <span className="mr-1 text-ellipsis overflow-hidden">
                            <If is={chat.type !== ChatType.Personal}>
                                <>{name}</>
                            </If>

                            <If is={chat.type === ChatType.Personal}>
                                <User.Name profile={chat.personalCompanionMember?.user.profile as ProfilePartial}/>
                            </If>
                        </span>

                        <If is={!!myMember?.muted}>
                            <div className="min-w-[18px]">
                                <Icon24MuteOutline width={18} height={18} fill="var(--icon_secondary)"/>
                            </div>
                        </If>

                        <If is={!!myMemberIsBlocked}>
                            <div className="min-w-[16px]">
                                <Icon20BlockOutline width={16} height={16} fill="var(--icon_secondary)"/>
                            </div>
                        </If>

                        <If is={!!companionMemberIsBlocked}>
                            <div className="min-w-[16px]">
                                <Icon20LockOutline width={16} height={16} fill="var(--icon_secondary)"/>
                            </div>
                        </If>
                    </div>

                    <div className="flex items-center gap-1 text-accent">
                        <If is={!!lastMessage?.isMine}>
                            <SvgComponent svgProps={{
                                fill: 'var(--accent)',
                                width: 20,
                                height: 20,
                            }} element={(
                                <>
                                    <If is={!!lastMessage?.isRead}>
                                        <Icon16CheckDoubleOutline/>
                                    </If>

                                    <If is={!lastMessage?.isRead}>
                                        {chatMessageStatusIcon[lastMessage?.status ?? ChatMessageStatus.Sending]}
                                    </If>
                                </>
                            )}/>
                        </If>

                        <Caption level="2" weight="regular" className="text-subhead first-letter:capitalize">
                            <If is={_.isNil(lastActionText)}>
                                <>{ChatService.parseChatDate(lastMessage?.createdAt)}</>
                            </If>

                            <If is={!_.isNil(lastActionText)}>
                                <>{lastActionText}</>
                            </If>
                        </Caption>
                    </div>
                </div>
            </RichCell>
        </ChatCellContainer>
    );
});


export { ChatCellItem };
