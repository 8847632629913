/**
 * SubscriptionInfo
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { SchoolItem } from '@/types/school';
import { ProductPriceType } from '@/codegen/graphql';

import { Icon12Star, Icon24StarCircleFillGreen } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { ProgressButton } from '@/components/Atoms/ProgressButton';

import { gradient } from '@/styles/modules/gradient';

import { Container } from './SubscriptionInfo.styled';


interface Props {
    access?: SchoolItem['access'];
}


const SubscriptionInfo = (props: Props) => {

    const { access } = props;

    const { t } = useI18n('components.School.SubscriptionInfo');

    return (
        <Container className="w-[197px]">
            <If is={access?.price?.type === ProductPriceType.Demo}>
                <div className="flex flex-1 flex-col items-center gap-2">
                    <span>
                        {t('demoAccessTill', {
                            dateOfDemoFinish: moment(access?.expireAt).format('DD MMM YYYY'),
                        })}
                    </span>

                    <Link pushPage={{ id: '/manage/school/subscription' }}>
                        <ProgressButton stretched
                                        size="l"
                                        type="static"
                                        mode="tertiary"
                                        wrapperClassName="w-full"
                                        data-test="school.upgrade"
                                        className="text-primary thin-border bg-content"
                                        before={<Icon24StarCircleFillGreen className="z-[1]" width={20} height={20}/>}
                                        progress={(
                                            100 - (
                                                moment().diff(access?.createdAt)
                                                / moment(access?.expireAt).diff(access?.createdAt)
                                            ) * 100
                                        )}
                                        progressClassName={[
                                            '!opacity-20 !bg-gradient-to-br',
                                            gradient.green500ToLime600,
                                        ].join(' ')}>
                            <span className="fs-15">
                                {access?.isActive ? t('upgrade') : t('activate')}
                            </span>
                        </ProgressButton>
                    </Link>
                </div>
            </If>

            <If is={access?.price?.type !== ProductPriceType.Demo}>
                <Link pushPage={{ id: '/manage/school/subscription' }}>
                    <div className="flex flex-col gap-0.5">
                        <div className="flex items-center gap-1">
                            <InnerHtml content={t('yourTariff', { tariffName: access?.price?.title })}/>
                            <Icon12Star fill="var(--accent)"/>
                        </div>

                        <div>
                            {t('nextPaymentAt', {
                                dateOfPayment: moment(access?.expireAt).format('DD MMM YYYY'),
                            })}
                        </div>
                    </div>
                </Link>
            </If>
        </Container>
    );
};


export { SubscriptionInfo };
