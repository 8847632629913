/**
 * PageContextModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal, Portal } from '@/cutils';

import { Container } from '@/modals/Page/Context/PageContext.styled';


interface Props {
    id: string;
}


const PageContextModal = (props: Props) => {
    return (
        <Container>
            <Modal.Card id={props.id}>
                <Modal.Body>
                    <Portal id={'menu:context'} renderer/>
                </Modal.Body>
            </Modal.Card>
        </Container>
    );
};


export { PageContextModal };
