/**
 * UseLessonPracticeManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { GqlResult } from '@/types/graphql';

import {
    CourseLessonPracticeFindOneDocument,
    CourseLessonPracticeFindOneQuery,
    CourseLessonPracticeFindOneQueryResult,
    CreateLessonPracticeCourseInput,
    UpdateLessonPracticeTaskOrderCourseInput,
    useCourseLessonPracticeManageCreateMutation,
    useCourseLessonPracticeManageUpdateMutation,
    useCourseLessonPracticeManageUpdateTaskOrderMutation,
} from '@/codegen/graphql';


export const useLessonPracticeManage = (
    courseId: number,
    lessonId: number,
) => {

    const getInitialValues = (
        practice?: GqlResult<CourseLessonPracticeFindOneQueryResult>['courseLessonPracticeFindOne'],
    ) => ({
        description: practice?.description || '',
        deadlineInDays: practice?.deadlineInDays || 0,
        taskUuids: practice?.tasks?.map((t) => t?.task?.uuid) || [],
    });

    const [ _createPractice, {
        loading: createPracticeLoading,
        error: createPracticeError,
    } ] = useCourseLessonPracticeManageCreateMutation({
        onError: (error) => console.error(error),
    });

    const createPractice = (practice: CreateLessonPracticeCourseInput) => {
        return _createPractice({
            variables: {
                courseId,
                lessonId,
                practice,
            },
            refetchQueries: [
                {
                    query: CourseLessonPracticeFindOneDocument,
                    variables: { courseId, lessonId },
                },
            ],
        });
    };

    const [ _updatePractice, {
        loading: updatePracticeLoading,
        error: updatePracticeError,
    } ] = useCourseLessonPracticeManageUpdateMutation({
        onError: (error) => console.error(error),
    });

    const updatePractice = (
        lessonPracticeId: number,
        practice: CreateLessonPracticeCourseInput,
    ) => {
        return _updatePractice({
            variables: {
                courseId,
                practice,
                lessonPracticeId,
            },
        });
    };

    const [ _updatePracticeTaskOrder, {
        loading: updatePracticeTaskOrderLoading,
        error: updatePracticeTaskOrderError,
    } ] = useCourseLessonPracticeManageUpdateTaskOrderMutation({
        onError: (error) => console.error(error),
    });

    const updatePracticeTaskOrder = (
        lessonPracticeId: number,
        items: UpdateLessonPracticeTaskOrderCourseInput['items'],
    ) => {
        return _updatePracticeTaskOrder({
            variables: {
                courseId,
                lessonPracticeId,
                taskOrder: { items },
            },
            update: (cache, { data }) => {
                const { courseLessonPracticeManageUpdateTaskOrder: orderedTasks } = data || {};

                const { courseLessonPracticeFindOne } = cache.readQuery<CourseLessonPracticeFindOneQuery>({
                    variables: { courseId, lessonId },
                    query: CourseLessonPracticeFindOneDocument,
                }) || {};

                const existTasks = courseLessonPracticeFindOne?.tasks;

                if (!orderedTasks || !existTasks) {
                    return console.warn('[Cache]: practice отсутствует в кэше');
                }

                const tasks = existTasks.map((task) => {
                    const orderedTask = _.find(orderedTasks, { id: task.id });

                    return orderedTask
                        ? _.assign(task, { order: orderedTask.order })
                        : task;
                });

                cache.modify({
                    id: `CourseLessonPracticeEntity:${lessonPracticeId}`,
                    fields: {
                        tasks: () => _.orderBy(tasks, 'order'),
                    },
                });

            },
        });
    };

    return {
        getInitialValues,
        createPractice,
        createPracticeError,
        createPracticeLoading,
        updatePractice,
        updatePracticeError,
        updatePracticeLoading,
        updatePracticeTaskOrder,
        updatePracticeTaskOrderError,
        updatePracticeTaskOrderLoading,
    };
};
