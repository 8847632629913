/**
 * LessonAvatarBadgePart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { Icon20BookSpreadOutline, Icon20VideoSquareOutline } from '@vkontakte/icons';

import { gradient } from '@/styles/modules/gradient';


interface Props {
    hasVideo: boolean;
    badgeSize: number;
}


const LessonAvatarBadgePart = (props: Props) => {

    const { hasVideo, badgeSize } = props;

    return (
        <div className="z-[1]" style={{ transform: 'translate3d(0, 0, 0)' }}>
            <If is={!hasVideo}>
                <div className={[
                    'bg-gradient-to-br p-1 rounded-full',
                    gradient.green500ToLime600,
                ].join(' ')}>
                    <Icon20BookSpreadOutline width={badgeSize} height={badgeSize} fill="var(--white)"/>
                </div>
            </If>

            <If is={hasVideo}>
                <div className={[
                    'bg-gradient-to-br p-1 rounded-full',
                    gradient.blue400ToBlue700Gradient,
                ].join(' ')}>
                    <Icon20VideoSquareOutline width={badgeSize} height={badgeSize} fill="var(--white)"/>
                </div>
            </If>
        </div>
    );
};


export { LessonAvatarBadgePart };
