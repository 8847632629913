/**
 * UserInviteModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Title } from '@exode.ru/vkui';
import { Icon36Users } from '@vkontakte/icons';

import { UserInviteView } from '@/pages/Profile/Share/views/UserInviteView';


interface Props {
    id: string;
}


const UserInviteModal = (props: Props) => {

    const { t } = useI18n('modals.User.Invite');

    return (
        <Modal.Card id={props.id}
                    icon={<Modal.CardIcon icon={<Icon36Users fill="var(--white)"/>}/>}
                    subheader={t('toGetRewards')}
                    header={(
                        <Title className="modal-title" level="2" weight="3">
                            {t('invitingFriends')}
                        </Title>
                    )}>
            <Modal.Body isScrollable>
                <UserInviteView/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { UserInviteModal };
