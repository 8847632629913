/**
 * UseProfileUpdate
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { UserAuthStore } from '@/store/user/auth';
import { ProfileStore } from '@/store/user/profile';

import { useProfileUpdateMutation, useSetUserDomainMutation } from '@/codegen/graphql';


export const useProfileUpdate = () => {

    const [ updateProfile, { loading: updateProfileLoading } ] = useProfileUpdateMutation({
        onError: error => console.error(error),
        update(cache, { data }) {
            const { profileUpdate } = data || {};

            if (profileUpdate) {
                const profile = _.omit(profileUpdate, 'user');

                cache.modify({
                    id: `UserEntity:${UserAuthStore.user?.id}`,
                    fields: {
                        profile: () => profile,
                        domain: () => profileUpdate.user.domain || null,
                    },
                });

                ProfileStore.merge({
                    ...profile,
                    user: {
                        ...ProfileStore.user,
                        domain: profileUpdate.user.domain,
                    },
                });
            }
        },
    });

    const [ setUserDomain, { loading: setUserDomainLoading } ] = useSetUserDomainMutation({
        onError: error => console.error(error),
    });

    return {
        updateProfile,
        updateProfileLoading,
        setUserDomain,
        setUserDomainLoading,
    };
};
