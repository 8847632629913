/**
 * ContentPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/preference/preference';

import { Portal } from '@/cutils';

import { ContentIconsPart } from '../parts/ContentIconsPart';


const ContentPart = observer(() => {
    return (
        <div className="viewport__content vkuiSplitCol--spaced h-full">
            <div data-test="page-header" className="flex items-center justify-between">
                <Portal id="panel-header-page:content" renderer/>
                <Portal id="panel-header-page:d:context" renderer/>

                <div className="flex items-center h-full w-fit ml-auto">
                    <ContentIconsPart/>
                </div>
            </div>
        </div>
    );
});


export { ContentPart };
