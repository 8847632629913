/**
 * UseSession
 *
 * @author: exode <hello@exode.ru>
 */

import { Router } from '@/services/Utils/Router';

import { MyUserSettingsDocument, useKillSessionsMutation } from '@/codegen/graphql';


export const useKillOtherSessions = () => {

    const [ killOtherSessions, { loading: killOtherSessionsLoading } ] = useKillSessionsMutation({
        refetchQueries: [ { query: MyUserSettingsDocument } ],
        onError: () => {
            Router.replaceModal();
        },
    });

    return { killOtherSessions, killOtherSessionsLoading };
};
