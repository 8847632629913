/**
 * UseTaskManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useApolloClient } from '@apollo/client';

import { ManageTasksListPageStore } from '@/pages/Manage/Tasks/List/store';

import { GqlResult } from '@/types/graphql';
import { TaskFindOneResult, TaskFindOneWithoutAnswer } from '@/types/task';

import { useBatchUpdateCacheFields, useEditorJs, useI18n, usePrompt } from '@/hooks/core';

import {
    EducationSubject,
    SubjectCategoryFindManyDocument,
    SubjectCategoryManageCreateMutationResult,
    TaskAnswerType,
    TaskComplexity,
    TaskEntity,
    TaskFindManyDocument,
    TaskFindManyQuery,
    TaskManageCreateMutationOptions,
    TaskManageUpdateMutationOptions,
    useSubjectCategoryManageCreateMutation,
    useTaskManageCreateMutation,
    useTaskManageUpdateMutation,
} from '@/codegen/graphql';

import { Notify } from '@/cutils';

import { Icon16Cancel } from '@vkontakte/icons';
import { TaskBuilderProps } from '@/components/Task/Builder';
import { useTaskBaseFilter } from '@/pages/Manage/Tasks/List/filter';


export type TaskBuilderFormValues = ReturnType<ReturnType<typeof useTaskManage>['getInitialValues']>;


export const useTaskManage = () => {

    const { t } = useI18n('hooks.apollo.task');

    const apolloClient = useApolloClient();
    const { dtoFilter } = useTaskBaseFilter();

    const { getEditorJsInitial } = useEditorJs();

    const [ taskManageCreate, { loading: createTaskLoading } ] = useTaskManageCreateMutation();
    const [ taskManageUpdate, { loading: updateTaskLoading } ] = useTaskManageUpdateMutation();

    const [
        _createSubjectCategory,
        { loading: createSubjectCategoryLoading },
    ] = useSubjectCategoryManageCreateMutation();

    const createTask = (options: TaskManageCreateMutationOptions) => taskManageCreate({
        onError(error) {
            console.error(error);

            Notify.vkui({
                message: error.message,
                appearance: 'error',
                icon: <Icon16Cancel/>,
            });
        },
        update: (cache, { data }) => {
            const variables = {
                list: { ...ManageTasksListPageStore.state.list },
                filter: {
                    ...dtoFilter,
                    administrate: false,
                    manage: true,
                },
            };

            const cachedTasks = cache.readQuery<TaskFindManyQuery>({
                query: TaskFindManyDocument,
                variables,
            });

            if (!cachedTasks) {
                return console.warn('[Cache]: cachedTasks отсутствуют в кэше');
            }

            data?.taskManageCreate && cache.writeQuery<TaskFindManyQuery>({
                query: TaskFindManyDocument,
                variables,
                data: {
                    taskFindMany: {
                        __typename: 'ListTaskOutput',
                        pages: cachedTasks.taskFindMany.pages,
                        items: [ data?.taskManageCreate, ...(cachedTasks.taskFindMany.items ?? []) ],
                    },
                },
            });
        },
        ...options,
    });

    const updateTask = async (options: TaskManageUpdateMutationOptions) => taskManageUpdate({
        update: (cache, { data }) => {
            const fields = data?.taskManageUpdate;

            fields && data?.taskManageUpdate && cache.modify({
                id: `TaskEntity:${fields.id}`,
                fields: useBatchUpdateCacheFields<TaskEntity>(fields),
            });
        },
        onError(error) {
            console.error(error);

            Notify.vkui({
                message: error.message,
                appearance: 'error',
                icon: <Icon16Cancel/>,
            });
        },
        ...options,
    });

    const createSubjectCategory = (
        subject: EducationSubject,
        onCreate?: (subjectCategory: GqlResult<SubjectCategoryManageCreateMutationResult>['subjectCategoryManageCreate']) => void,
    ) => {
        const { openPrompt: openConfirmCreateCategory } = usePrompt({
            title: t('creatingTopic'),
            subtitle: t('youCanCreateTopicsToBeExplored'),
            isLoading: createSubjectCategoryLoading,
            buttons: {
                confirm: { text: t('create') },
            },
            prompt: {
                required: true,
                mode: 'input',
                minLength: 2,
                maxLength: 20,
                placeholder: t('topicTitle'),
            },
            onConfirm: ({ prompt }) => _createSubjectCategory({
                variables: {
                    category: {
                        subject,
                        name: `${prompt}`,
                    },
                },
                onCompleted: ({ subjectCategoryManageCreate }) => {
                    onCreate?.(subjectCategoryManageCreate);

                    apolloClient.refetchQueries({
                        include: [
                            SubjectCategoryFindManyDocument,
                        ],
                    });
                },
            }),
        });

        return { openConfirmCreateCategory };
    };

    const getInitialValues = (
        task?: TaskFindOneResult | TaskFindOneWithoutAnswer,
        taskPartial?: Partial<TaskBuilderProps['task']> | null,
    ) => {
        return task
            ? {
                ..._.pick(task, [
                    'subject',
                    'contentTypes',
                    'answerType',
                    'complexity',
                    'explanation',
                    'maxPoint',
                    'archived',
                ]),
                quizAnswer: 'quizAnswer' in task ? task.quizAnswer : [],
                subjectCategoryId: task.subjectCategory?.id,
                question: { ...task.question, storageFileIds: [] },
                ...(taskPartial || {}),
            }
            : {
                subject: EducationSubject.EgeRussian,
                subjectCategoryId: null as unknown as number,
                contentTypes: [],
                answerType: TaskAnswerType.Single,
                complexity: TaskComplexity.Medium,
                quizAnswer: [] as TaskEntity['quizAnswer'],
                archived: false,
                maxPoint: 1,
                question: {
                    text: { ...getEditorJsInitial() },
                    title: { ...getEditorJsInitial() },
                    subtext: { ...getEditorJsInitial() },
                    variants: [ '' ],
                    storageFileIds: [],
                    mapping: {
                        titles: [],
                        variants: [],
                        answers: [],
                    },
                },
                explanation: {
                    text: { ...getEditorJsInitial() },
                    video: '',
                },
                ...(taskPartial || {}),
            };
    };

    return {
        createTask,
        createTaskLoading,
        updateTask,
        updateTaskLoading,
        createSubjectCategory,
        createSubjectCategoryLoading,
        getInitialValues,
    };
};
