/**
 * Chat dialog styled items
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { isWindows } from 'react-device-detect';

import { customScrollbar } from '@/assets/styles/modules/scrollbar';


export const ChatCellContainer = styled.div`
  min-height: 75px;

  .vkuiRichCell__before {
    display: flex;
  }
`;

export const MessageItemContainer = styled.div`

`;

export const UserItemContainer = styled.div`
  .vkuiCell__content {
    border-radius: 0 !important;
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export const ScrollContainer = styled.div`
  ${isWindows && customScrollbar()}
`;

export const chatIconClass = 'opacity-70 hover:opacity-100 cursor-pointer';
