/**
 * DesktopNavigationMenuDrawer styled component
 *
 * @author: exode <hello@exode.ru>
 */

import { Drawer } from '@mui/material';

import styled from 'styled-components';


export const DrawerContainer = styled(Drawer)`
    font-family: var(--font-common), sans-serif;

    & .MuiPaper-root {
        color: inherit;

        & .SimpleCell {
            border-width: 0;
        }
    }

    & .MuiDrawer-paperAnchorLeft {
        min-width: 230px;
        background-color: var(--background_content);

        .viewport__left-menu {
            width: 230px;
            max-width: 230px;
        }
    }

    & .MuiDrawer-paperAnchorRight {
        min-width: 300px;
        background-color: var(--background_content);
    }
`;
