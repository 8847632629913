/**
 * List all existing paths
 *
 * @author: exode <hello@exode.ru>
 */

export type FrontendRoutePathType = typeof PathsList[number];

export const PathsList = [

    /** APP LOGIC ROUTES: */

    /** Core */
    '/404',
    '/app',

    /** TabRoutes */
    '/',
    '/actual',
    '/education',
    '/education/:page([0-9]+)',
    '/catalog',
    '/catalog/:page([0-9]+)',

    '/tournaments',
    '/menu',

    /** FEATURE-BASE ROUTES: (A-Z) */

    /** AchievementRoutes */
    '/achievements',

    /** BookmarkRoutes */
    '/bookmarks',

    /** ChatRoutes */
    '/chat',
    '/chat/attachments',
    '/chat/members/:page([0-9]+)',

    /** Collaborations */
    '/become/partner',
    '/become/partner/:requestId([0-9]+)',
    '/become/partner',

    /** CompanyRoutes */
    '/about',
    '/support',
    '/support/account-deletion-request',

    /** CourseRoutes */
    '/catalog',
    '/catalog/:page([0-9]+)',
    '/courses/enrolled',
    '/courses/:page([0-9]+)/:courseId([0-9]+)',
    '/course/:courseId([0-9_A-Za-z]+)',
    '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)',

    /** ManageRoutes (IMPORTANT: Order matters, especially for course) */
    '/manage',
    '/manage/seller',
    '/manage/seller/documents',
    '/manage/seller/become-request/:requestId([0-9]+)',
    '/manage/seller/balance/:page([0-9]+)',
    '/manage/courses/:page([0-9]+)',
    '/manage/course/:courseId([0-9]+)/navigator',
    '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)',
    '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)',
    '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)/settings',
    '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)/practice',
    '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)/practice',
    '/manage/course/:courseId([0-9]+)/information/branding',
    '/manage/course/:courseId([0-9]+)/information/details',
    '/manage/course/:courseId([0-9]+)/information/author',
    '/manage/course/:courseId([0-9]+)/information/curator',
    '/manage/course/:courseId([0-9]+)/information/actions',
    '/manage/course/:courseId([0-9]+)/information/order',
    '/manage/course/:courseId([0-9]+)/information/seo',
    '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)',
    '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/settings',
    '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/publication',
    '/manage/course/:courseId([0-9]+)/discounts/:page([0-9]+)',
    '/manage/course/:courseId([0-9]+)/comments',
    '/manage/course/:courseId([0-9]+)/certificates',
    '/manage/course/:courseId([0-9]+)/faq',
    '/manage/course/:courseId([0-9]+)/faq/:faqId([0-9]+)',
    '/admin/article/:articleId([0-9]+)',
    '/manage/practice',
    '/manage/students/:page([0-9]+)',
    '/manage/students/:page([0-9]+)/:courseId([0-9]+)',
    '/manage/students/:page([0-9]+)/:courseId([0-9]+)/:launchId([0-9]+)',
    '/manage/students/:page([0-9]+)/student/:accessId',
    '/manage/school/users/:page([0-9]+)',
    '/manage/payouts/:page([0-9]+)',
    '/manage/seller/balance/:page([0-9]+)',
    '/manage/invoices/:page([0-9]+)',
    '/manage/invoices/:page([0-9]+)/:invoiceUuid',
    '/manage/payments/:page([0-9]+)',
    '/manage/refunds/:page([0-9]+)',
    '/manage/reports/sales',
    '/manage/tournament/create',
    '/manage/articles',
    '/manage/articles/:articleId([0-9]+)',
    '/manage/stories',
    '/manage/stories/create',
    '/manage/stories/:storiesId([0-9]+)',
    '/manage/tasks/:page([0-9]+)',
    '/manage/tasks/:page([0-9]+)/:taskId([0-9_A-Za-z-_]+)',
    '/manage/tasks/create',
    '/manage/seller/product-managers/add',
    '/manage/seller/product-managers/:page([0-9]+)',
    '/manage/seller/managers/add',
    '/manage/seller/managers/:page([0-9]+)',
    '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/permissions',
    '/manage/seller/product-managers/:page([0-9]+)/:productManagerId([0-9]+)/permissions',
    '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/info',
    '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/statistics',
    '/manage/school',
    '/manage/school/settings/basic',
    '/manage/school/settings/preferences',
    '/manage/school/settings/blocks',
    '/manage/school/settings/left-menu',
    '/manage/school/settings/custom-code',
    '/manage/school/acquiring',
    '/manage/school/subscription',
    '/manage/school/disciplines',
    '/manage/school/analytics',
    '/manage/school/notifications',
    '/manage/school/legal',
    '/manage/school/privacy',
    '/manage/school/managers/:page([0-9]+)',
    '/manage/school/managers/:page([0-9]+)/:managerId([0-9]+)/permissions',
    '/manage/school/managers/add',
    '/manage/school/product-managers/:page([0-9]+)',
    '/manage/school/product-managers/:page([0-9]+)/:productManagerId([0-9]+)/permissions',
    '/manage/school/product-managers/add',
    '/manage/my-schools',

    /** AdminManageRoutes */
    '/admin/users/:page([0-9]+)',
    '/admin/users/:page([0-9]+)/:userId([0-9]+)/permissions',
    '/admin/users/:page([0-9]+)/:userId([0-9]+)/info',
    '/admin/users/:page([0-9]+)/:userId([0-9]+)/statistics',
    '/admin/partner-requests/:page([0-9]+)',
    '/admin/partner-requests/:page([0-9]+)/:requestId([0-9]+)',
    '/admin/articles/:page([0-9]+)',
    '/admin/articles/:page([0-9]+)',
    '/admin/article/:articleId([0-9]+)',

    /** ExamRoutes */
    '/exams-timeout',
    '/exams-subject/:subject([a-z-]+)',

    /** ProfileRoutes */
    '/friends',
    '/likes',
    '/notifications',
    '/@:userId([0-9_A-Za-z]+)',
    '/@:userId([0-9_A-Za-z]+)/friends',
    '/my-subjects',
    '/share',
    '/wallet',
    '/wallet/:page([0-9]+)',
    '/wallet/send',
    '/wallet/send/:userId([0-9]+)',
    '/wallet/send/successful',

    /** PromotionRoutes */
    '/promo/bonus',
    '/referral/:referralId([0-9_A-Za-z-_]+)',

    /** QuestionRoutes */
    '/my-questions',

    /** RangeRoutes */
    '/range',

    /** SchoolRoutes */
    '/school/create',

    /** TaskRoutes */
    '/task/:taskUuid([0-9_A-Za-z_-]+)',

    /** TournamentRoutes */
    '/tournaments/:page([0-9]+)',
    '/tournaments/history',
    '/tournaments/history/ongoing/:page([0-9]+)',
    '/tournaments/history/waiting/:page([0-9]+)',
    '/tournaments/history/finished/:page([0-9]+)',
    '/tournaments/create',
    '/tournaments/subjects-select',
    '/tournaments/blitz-select',
    '/tournament/blitz/waiting/:subject([a-z-]+)',
    '/tournament/invite/:tournamentId([0-9]+)',
    '/tournament/lobby/:tournamentId([0-9]+)',
    '/tournament/members/:tournamentId([0-9]+)',
    '/tournament/friends/:tournamentId([0-9]+)',
    '/tournament/process/:tournamentId([0-9]+)',
    '/tournament/process/:tournamentId([0-9]+)/progress',
    '/tournament/result/:tournamentId([0-9]+)/:page([0-9]+)',

    /** PayRouter */
    '/pay/:productId([0-9]+)/:priceId([0-9]+)',
    '/pay/:customName([0-9_A-Za-z_-]+)/:productId([0-9]+)/:priceId([0-9]+)',
    '/cart',
    '/cart/:cartUuid([0-9_A-Za-z_-]+)',
    '/payment/:invoiceUuid([0-9_A-Za-z_-]+)',

    /** UserRouter */
    '/login',
    '/login/app',
    '/login/recover',
    '/login/password/change',
    '/login/signin',
    '/login/email/signup',
    '/settings',
    '/settings/two-factor',
    '/settings/password',
    '/profile/main-info',

    /** UtilsRouter */
    '/utils/page',

    /** WikiRoutes */
    '/wiki',

] as const;
