/**
 * MenuIconPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ForwardedRef, ReactElement } from 'react';

import { CellButton, Counter } from '@exode.ru/vkui';


interface Props {
    icon: ReactElement;
    title: string;
    ref: ForwardedRef<any>;
    counter?: number;
}


const MenuIconPart = React.forwardRef<any, Props>(function Item(props, ref) {
    return (
        <div {...props} ref={ref} className="flex justify-center">
            <CellButton centered
                        multiple
                        hasActive={false}
                        className="relative select-none"
                        subtitle={<span className="fs-10">{props.title}</span>}
                        after={props.counter && (
                            <Counter size="s" className="absolute top-1 right-[20px]" mode="prominent">
                                {props.counter}
                            </Counter>
                        )}>
                <div className="w-full flex items-center m-auto">
                    {props.icon}
                </div>
            </CellButton>
        </div>
    );
});

export { MenuIconPart };
