/**
 * AppSplitColumn
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PageStore } from '@/store/core/page';
import { ConfigStore } from '@/store/core/config';

import { If, Responsive } from '@/cutils';
import { EpicViewType } from '@/types/router';
import { Panel, SplitCol, View } from '@exode.ru/vkui';

import { PanelHeaderSpace, PanelHeaderTab } from '@/components/Panel/Header';
import { PermanentPageContentTop } from '@/components/App/PermanentRender';
import { DesktopNavigationContextMenu, DesktopNavigationMenu } from '@/components/Desktop/Navigation';

import { EpicRoot } from '../root-views/EpicRoot';


interface AppContainProps {
    type: EpicViewType;
}


export const AppSplitColumn = observer(({ type }: AppContainProps) => {

    const className = [
        'viewport__wrapper',
        `viewport--${type}`,
        type === 'iframe' ? 'viewport__wrapper--full' : '',
    ].join(' ');

    return (
        <div id="viewport-wrapper" className={className} data-page-mode={PageStore.mode}>
            <If is={type !== 'iframe'}>
                <DesktopNavigationMenu/>
            </If>

            <SplitCol spaced={ConfigStore.isDesktop} className="viewport__content viewport__content--page">

                <If is={type !== 'iframe'}>
                    <PanelHeaderSpace offset={18}/>
                </If>

                <Responsive.Mobile>
                    <PanelHeaderTab/>
                </Responsive.Mobile>

                <View activePanel="epic-root"
                      data-page-space={PageStore.bottomSafeSpace}
                      data-page-entire-screen={PageStore.entireScreen}>
                    <Panel id="epic-root">
                        <PermanentPageContentTop/>

                        <EpicRoot/>
                    </Panel>
                </View>
            </SplitCol>

            <If is={type !== 'iframe'}>
                <DesktopNavigationContextMenu/>
            </If>
        </div>
    );
});
