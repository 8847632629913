/**
 * Apollo cache
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Modifier } from '@apollo/client/cache/core/types/common';


const useBatchUpdateCacheFields = <Entity, >(fields: Record<any, any>) => {
    return _.entries(fields).map(([ f, v ]) => ({ [f]: () => v })) as unknown as Modifier<Entity>;
};


export { useBatchUpdateCacheFields };
