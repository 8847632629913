/**
 * UseNotificationRead
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import {
    NotificationsDocument,
    NotificationsQuery,
    NotificationStatus,
    useReadAllNotificationsMutation,
    useReadNotificationMutation,
} from '@/codegen/graphql';

import { notificationListAndSortConfig } from '@/pages/Profile/Notifications/views/UnreadNotificationsView';


export const useNotificationRead = (notificationId?: number) => {
    const [ _readNotification, { loading: readNotificationLoading } ] = useReadNotificationMutation();
    const [ _readAllNotifications, { loading: readAllNotificationLoading } ] = useReadAllNotificationsMutation();

    const readNotification = async () => {
        notificationId && await _readNotification({
            variables: { notificationId },
            update: (cache, { data }) => {
                if (!data) {
                    return;
                }

                const id = data?.notificationReadNotification.id;

                const cachedUnread = cache.readQuery<NotificationsQuery>({
                    query: NotificationsDocument,
                    variables: {
                        ...notificationListAndSortConfig,
                        filter: { statuses: [ NotificationStatus.Unread ] },
                    },
                });

                const cachedRead = cache.readQuery<NotificationsQuery>({
                    query: NotificationsDocument,
                    variables: {
                        ...notificationListAndSortConfig,
                        filter: { statuses: [ NotificationStatus.Read ] },
                    },
                });

                if (!cachedUnread) {
                    return console.warn('[Cache]: cachedUnread отсутствуют в кэше');
                }

                const unreadNotification = cachedUnread!.notificationFindMany.items!.find((item) => item.id === id)!;

                cache.writeQuery<NotificationsQuery>({
                    query: NotificationsDocument,
                    variables: {
                        ...notificationListAndSortConfig,
                        filter: { statuses: [ NotificationStatus.Unread ] },
                    },
                    data: {
                        notificationFindMany: {
                            __typename: 'ListNotificationOutput',
                            count: cachedUnread!.notificationFindMany.count - 1,
                            items: cachedUnread!.notificationFindMany.items?.filter((item) => item.id !== id),
                        },
                    },
                });

                cache.writeQuery<NotificationsQuery>({
                    query: NotificationsDocument,
                    variables: {
                        ...notificationListAndSortConfig,
                        filter: { statuses: [ NotificationStatus.Read ] },
                    },
                    data: {
                        notificationFindMany: {
                            __typename: 'ListNotificationOutput',
                            count: (cachedRead?.notificationFindMany.count || 0) + 1,
                            items: _.orderBy([
                                unreadNotification,
                                ...(cachedRead?.notificationFindMany.items ?? []),
                            ], 'id', 'desc'),
                        },
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    const readAllNotifications = async () => {
        await _readAllNotifications({
            update: (cache, { data }) => {
                const cachedUnread = cache.readQuery<NotificationsQuery>({
                    query: NotificationsDocument,
                    variables: {
                        ...notificationListAndSortConfig,
                        filter: { statuses: [ NotificationStatus.Unread ] },
                    },
                });

                const cachedRead = cache.readQuery<NotificationsQuery>({
                    query: NotificationsDocument,
                    variables: {
                        ...notificationListAndSortConfig,
                        filter: { statuses: [ NotificationStatus.Read ] },
                    },
                });

                if (!cachedUnread || !cachedRead) {
                    return console.warn('[Cache]: cachedUnread || cachedRead отсутствуют в кэше');
                }

                const allUnreadToRead = cachedUnread!.notificationFindMany.items!.map(item => ({
                    ...item,
                    status: NotificationStatus.Read,
                }));

                data && cache.writeQuery<NotificationsQuery>({
                    query: NotificationsDocument,
                    variables: {
                        ...notificationListAndSortConfig,
                        filter: { statuses: [ NotificationStatus.Unread ] },
                    },
                    data: {
                        notificationFindMany: {
                            __typename: 'ListNotificationOutput',
                            count: 0,
                            items: null,
                        },
                    },
                });

                data && cache.writeQuery<NotificationsQuery>({
                    query: NotificationsDocument,
                    variables: {
                        ...notificationListAndSortConfig,
                        filter: { statuses: [ NotificationStatus.Read ] },
                    },
                    data: {
                        notificationFindMany: {
                            __typename: 'ListNotificationOutput',
                            count: (cachedRead?.notificationFindMany.count || 0) + cachedUnread!.notificationFindMany.count,
                            items: _.orderBy([
                                ...(cachedRead?.notificationFindMany.items ?? []),
                                ...allUnreadToRead,
                            ], 'id', 'desc'),
                        },
                    },
                });

                cache.modify({
                    id: cache.identify({ __typename: 'Query' }),
                    fields: {
                        notificationCountByStatus: () => 0,
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    return {
        readNotification,
        readNotificationLoading,
        readAllNotifications,
        readAllNotificationLoading,
    };
};
