/**
 * SvgComponent util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';


interface Props<P> {
    element: ReactElement<P>,
    svgProps?: P;
}

const SvgComponent = <P, >(props: Props<P>) => {

    const { element, svgProps } = props;

    return React.cloneElement(element, { ...svgProps });
};


export { SvgComponent };
