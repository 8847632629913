/**
 * FriendsContentView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { FriendshipStatus, ListFriendsByStatusQuery, PossibleFriendsListQuery } from '@/codegen/graphql';

import { Graphql, If } from '@/cutils';

import { UserListSkeleton } from '@/components/Atoms/Skeleton/UserList';
import { ProfileSearchWrapper } from '@/components/Profile/SearchWrapper';

import { FriendsSearchView } from './FriendsSearchView';
import { FriendsRequestedView } from './FriendsRequestedView';
import { FriendsConfirmedView } from './FriendsConfirmedView';
import { FriendsPossibleView } from './FriendsPossibleView';

import { possibleFriendsListConfig } from './FriendsContextView';


export const friendsByStatusListConfig = { list: { skip: 0, take: 20 } };

const FriendsContentView = () => {
    return (
        <>
            <ProfileSearchWrapper successComponent={<FriendsSearchView/>}
                                  loadingComponent={<FriendsSearchView profiles={{ items: [], count: 0 }}/>}/>

            <ListFriendsByStatusQuery children={(result) => (
                <>
                    <Graphql.Success result={result}>
                        {({ friendshipFindMany: friends }) => (
                            <If is={!!friends.items?.length}>
                                <FriendsRequestedView {...friends}/>
                            </If>
                        )}
                    </Graphql.Success>

                    <Graphql.Error result={result}/>
                </>
            )} variables={{
                ...friendsByStatusListConfig,
                filter: { status: FriendshipStatus.Requested },
            }}/>

            <ListFriendsByStatusQuery children={(result) => (
                <>
                    <Graphql.Loading result={result}>
                        <UserListSkeleton mode="friends"/>
                    </Graphql.Loading>

                    <Graphql.Success result={result}>
                        {({ friendshipFindMany: friends }) => (
                            <FriendsConfirmedView friends={friends}/>
                        )}
                    </Graphql.Success>

                    <Graphql.Error result={result}/>
                </>
            )} variables={{
                ...friendsByStatusListConfig,
                filter: { status: FriendshipStatus.Confirmed },
            }}/>

            <PossibleFriendsListQuery children={(result) => (
                <>
                    <Graphql.Loading result={result}>
                        <UserListSkeleton mode="friends" friends={{ isPossible: true }}/>
                    </Graphql.Loading>

                    <Graphql.Success result={result}>
                        {({ friendshipFindManyPossible: friends }) => (
                            <FriendsPossibleView friends={friends}/>
                        )}
                    </Graphql.Success>

                    <Graphql.Error result={result}/>
                </>
            )} variables={possibleFriendsListConfig}/>
        </>
    );
};


export { FriendsContentView };
