/**
 * DesktopManageServicesList component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Dispatch, SetStateAction } from 'react';

import { observer } from '@/store/user/auth';
import { SellerStore } from '@/store/user/seller';

import { If, Responsive } from '@/cutils';

import { Separator } from '@exode.ru/vkui';

import { shouldDrawerBeShown } from './helper';

import { SectionPart } from './parts/Section';
import { ManageSellerLogo } from './parts/ManageSellerLogo';
import { Container } from './DesktopManageServicesList.styled';


export interface DesktopManageServicesListProps {
    shown: boolean;
    setShown: Dispatch<SetStateAction<boolean>>;
    currentSectionItem: string;
    setCurrentSectionItem: Dispatch<SetStateAction<string>>;
}


const DesktopManageServicesList = observer(() => {
    return (
        <If is={shouldDrawerBeShown()}>
            <Responsive.Desktop>
                <Container className={[
                    SellerStore.drawer.open ? 'is-opened' : 'is-closed',
                    'desktop-manage-services-list thin-border-left',
                ].join(' ')}>
                    <div className="wrapper scrollbar-hidden--windows">
                        <ManageSellerLogo/>

                        <Separator className="w-full my-3"/>

                        <SectionPart section="commercial"/>
                        <SectionPart section="interactions"/>
                        <SectionPart section="content"/>
                        <SectionPart section="administration"/>
                    </div>
                </Container>
            </Responsive.Desktop>
        </If>
    );
});


export { DesktopManageServicesList };
