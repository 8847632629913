/**
 * UseCourseProgress
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { apolloClient } from '@/api/graphql';

import { useLocation } from '@/router/index';
import { ConfigStore } from '@/store/core/config';

import {
    CourseLessonGetProgressStatusDocument,
    CourseLessonGetProgressStatusQuery,
    CourseProgressFindManyCurrentDocument,
    CourseProgressLessonStatus,
    CourseProgressType,
    ProductEntity,
    useCourseProgressStartNextManualMutation,
    useCourseProgressUpdateByParticipantMutation,
} from '@/codegen/graphql';

import { CourseLessonItems } from '@/types/course';

import { Router } from '@/services/Utils/Router';
import { CourseService } from '@/services/Course/Course';


export const useCourseProgressChangeStatus = (
    lessonId: number,
    progressStatus: CourseProgressLessonStatus | undefined | null,
    toStatus: CourseProgressLessonStatus,
) => {
    const updateProgressStatusKey = 'updateProgressStatus';

    if (!progressStatus) {
        return { [updateProgressStatusKey]: () => {} };
    }

    const [ _updateProgress ] = useCourseProgressUpdateByParticipantMutation({
        onError: error => console.error(error),
        onCompleted: () => {
            apolloClient.refetchQueries({
                include: [
                    CourseProgressFindManyCurrentDocument,
                ],
            });
        },
    });

    const common = [
        CourseProgressLessonStatus.NotStarted,
        CourseProgressLessonStatus.AvailableAsDemo,
        CourseProgressLessonStatus.FeatureAvailable,
    ];

    const allowed: { [key in CourseProgressLessonStatus]?: CourseProgressLessonStatus[] } = {
        [CourseProgressLessonStatus.OnTheory]: common,
        [CourseProgressLessonStatus.OnPractice]: [
            ...common,
            CourseProgressLessonStatus.OnTheory,
        ],
    };

    const availableChange = allowed?.[toStatus]?.includes(progressStatus);

    if (!availableChange) {
        return { [updateProgressStatusKey]: () => {} };
    }

    const updateProgressStatus = () => (
        _updateProgress({
            variables: { lessonId, progress: { status: toStatus } },
        })
    );

    return { [updateProgressStatusKey]: updateProgressStatus };
};

export const useCourseLessonProgress = (
    courseId: number,
    lessonId?: number,
) => {

    const { route: { params: { page } } } = useLocation();

    const [
        manualStartNextLesson,
        { loading: manualStartNextLessonLoading },
    ] = useCourseProgressStartNextManualMutation({
        onCompleted: ({ courseProgressStartNextManual }) => {
            if (courseProgressStartNextManual) {
                Router.pushPage(
                    '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)',
                    {
                        courseId: `${courseId}`,
                        lessonId: `${courseProgressStartNextManual?.lesson?.id}`,
                    },
                );
            }
        },
        update: (cache, { data }) => {
            const startedLesson = data?.courseProgressStartNextManual?.lesson;

            if (lessonId && startedLesson) {
                cache.modify({
                    id: `CourseLessonEntity:${lessonId}`,
                    fields: {
                        myProgress: () => CourseProgressLessonStatus.Completed,
                    },
                });

                cache.writeQuery<CourseLessonGetProgressStatusQuery>({
                    query: CourseLessonGetProgressStatusDocument,
                    variables: { lessonId: startedLesson.id },
                    data: {
                        __typename: 'Query',
                        courseLessonGetProgressStatus: CourseProgressLessonStatus.NotStarted,
                    },
                });

                [
                    'courseLessonGetProgressStatus',
                    'courseProgressFindManyCurrent',
                ].map((fieldName) => {
                    cache.evict({ id: 'ROOT_QUERY', fieldName });
                });
            }
        },
    });

    const openLessonOrNextWithContent = (
        lesson: Pick<CourseLessonItems[number],
            'id' |
            'order' |
            'parent' |
            'withContent'
        > | null | undefined,
        lessons: CourseLessonItems,
    ) => {
        if (!lesson) {
            return null;
        }

        return lesson.withContent
            ? lesson
            : _.find(lessons, ({ order, withContent }) => (
                withContent && order > lesson.order
            ));
    };

    const tryToStartNextLesson = async (
        currentLessonId: number,
        course: {
            progressType: CourseProgressType;
        },
        previous: {
            withPractice: boolean;
            canStartNextLesson: boolean;
        } | undefined | null,
        progressStatus: CourseProgressLessonStatus | undefined | null,
        forceTryStart = false,
    ) => {
        const isCallStartLesson = forceTryStart
            || CourseService.canStartNextFeatureDisabled(
                course,
                previous,
                progressStatus,
                { withDemo: true },
            );

        if (!isCallStartLesson) {
            return;
        }

        return manualStartNextLesson({
            variables: {
                courseId,
                currentLessonId,
            },
        });
    };

    const handleOpenLesson = async (
        course: {
            progressType: CourseProgressType,
            product: Pick<ProductEntity, 'isFree'>
        },
        lesson: {
            id: number;
            order?: number | null;
            myProgressStatus?: CourseProgressLessonStatus | null;
            previous?: {
                withPractice: boolean;
                canStartNextLesson: boolean;
            } | null;
        } | null | undefined,
        currentLesson?: {
            id: number;
            withPractice: boolean;
            order?: number | null;
        } | null,
    ) => {
        if (!lesson) {
            return;
        }

        const { NotInitialized, NotParticipant } = CourseProgressLessonStatus;

        /** Can start only manually by curator */
        if (!CourseService.nextLessonStartManually(course, currentLesson)) {
            if (currentLesson?.order) {
                const lessonOrder = (lesson.order || 0);

                const isPrevAndNotInitialized = lessonOrder < currentLesson.order
                    && lesson.myProgressStatus === NotInitialized;

                if (lessonOrder > currentLesson.order || isPrevAndNotInitialized) {
                    await tryToStartNextLesson(
                        isPrevAndNotInitialized ? lesson.id : currentLesson.id,
                        course,
                        lesson.previous,
                        lesson.myProgressStatus,
                        isPrevAndNotInitialized,
                    );
                }
            }
        }

        /** Auto-initialize progress (if not exist and is participant) */
        if (!currentLesson && lesson.myProgressStatus === NotInitialized) {
            await tryToStartNextLesson(
                lesson.id,
                course,
                lesson.previous,
                lesson.myProgressStatus,
                true,
            );
        }

        if (ConfigStore.isDesktop && lesson.myProgressStatus === NotParticipant) {
            return Router.pushModal('course-not-participant', {
                modalLessonId: `${lesson.id}`,
                productIsFree: course.product.isFree ? 'true' : 'false',
            });
        }

        return Router.pushPage(
            '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)',
            { page, courseId: `${courseId}`, lessonId: `${lesson.id}`, tab: 'lesson' },
        );
    };

    return {
        handleOpenLesson,
        tryToStartNextLesson,
        manualStartNextLesson,
        manualStartNextLessonLoading,
        openLessonOrNextWithContent,
    };
};
