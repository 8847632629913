/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { UserAuthStore } from '@/store/user/auth';

import { useSellerContext } from '@/components/App/SellerProvider';


/**
 * Should drawer be shown by permissions
 * @returns {boolean}
 */
export const shouldDrawerBeShown = () => {
    const { seller } = useSellerContext();

    return UserAuthStore.isLoggedIn && !_.isEmpty(UserAuthStore.combinedPermissions) || !!seller;
};
