/**
 * Modal cards and paths by id
 *
 * @author: exode <hello@exode.ru>
 */

/** Common modals */
import { AvailableInAppModal } from '@/modals/Utils/AvailableInApp';
import { CompanyAboutTextModal } from '@/modals/Company/AboutText';
import { CompanyDetailsModal } from '@/modals/Company/Details';
import { ConfirmationModal } from '@/modals/Utils/Confirmation';
import { CoursePricingPlanModal } from '@/modals/Course/PricingPlan';
import { ChatAttachmentsModal } from '@/modals/Chat/Attachments';
import { ChatMembersModal } from '@/modals/Chat/ChatMembers';
import { ChatForwardMessageModal } from '@/modals/Chat/ForwardMessage';
import { CourseFiltersModal } from '@/modals/Course/Filters';
import { CourseNotParticipantModal } from '@/modals/Course/NotParticipant';
import { DialogModal } from '@/modals/Utils/Dialog';
import { EgeSubjectInfoModal } from '@/modals/Ege/SubjectInfo';
import { EgeSubjectDatesModal } from '@/modals/Ege/SubjectDates';
import { CourseShareModal } from '@/modals/Course/Share';
import { PageContextModal } from '@/modals/Page/Context';
import { ShortFormBecomePartnerModal } from '@/modals/Partner/ShortFormBecomePartner';
import { ProfileFriendsModal } from '@/modals/Profile/Friends/ProfileFriends';
import { ProfileSetStatusModal } from '@/modals/Profile/ProfileSetStatus';
import { ProfileWalletDepositModal } from '@/modals/Profile/Wallet/ProfileWalletDeposit';
import { SelectMimicryModal } from '@/modals/Utils/SelectMimicryModal';
import { StoryModal } from '@/modals/Education/Story';
import { TournamentCardInfoModal } from '@/modals/Tornament/CardInfo';
import { TournamentSubjectSelectModal } from '@/modals/Tornament/SubjectSelect';
import { TournamentFiltersModal } from '@/modals/Tornament/Filters/TournamentFilters';
import { TournamentFriendsModal } from '@/modals/Tornament/Friends';
import { TournamentCreateModal } from '@/modals/Tornament/Create';
import { TournamentShareModal } from '@/modals/Tornament/Share';
import { TournamentBlitzSelectModal } from '@/modals/Tornament/BlitzSelect';
import { TournamentInviteModal } from '@/modals/Tornament/Invite';
import { TournamentMembersModal } from '@/modals/Tornament/Members';
import { TournamentParticipantsProgressModal } from '@/modals/Tornament/ParticipantsProgress';
import { TournamentQuickActionsModal } from '@/modals/Tornament/QuickActions';
import { PasswordChangeModal } from '@/modals/User/PasswordChange';
import { PhotoEditorModal } from '@/modals/Utils/PhotoEditor';
import { TwoFactorSetModal } from '@/modals/User/TwoFactorSet';
import { UserMenuModal } from '@/modals/User/Menu';
import { UserInviteModal } from '@/modals/User/Invite';
import { UserMainInfoModal } from '@/modals/User/MainInfo';

/** Manage modals */
import { ManageUserCreateModal } from '@/modals/Manage/User/Create';
import { ManageTaskSearchModal } from '@/modals/Manage/Task/TaskSearch';
import { ManageCourseCreateModal } from '@/modals/Manage/Course/Create';
import { ManageSellerInfoModal } from '@/modals/Manage/Seller/SellerInfo';
import { ManageCourseDiscountModal } from '@/modals/Manage/Course/Discount';
import { ManageSellerPayoutCreateModal } from '@/modals/Manage/Seller/SellerPayoutCreate';
import { ManageSellerRequestModal } from '@/modals/Manage/Seller/SellerRequest';
import { ManageSellerBalanceOperationModal } from '@/modals/Manage/Seller/SellerBalanceOperation';
import { ManageSchoolDisciplinesModal } from '@/modals/Manage/School/SchoolManageDisciplines';
import { ManageSchoolAcquiringConnectModal } from '@/modals/Manage/School/SchoolAcquiringConnect';
import { ManageSchoolLegalDocModal } from '@/modals/Manage/School/SchoolLegalDoc/ManageSchoolLegalDoc';


export type ModalTypes = typeof ModalCards[number]['id'];

export const ModalCards = [

    /** Chat modals */
    {
        id: 'chat-forward-message',
        modal: ChatForwardMessageModal,
    },
    {
        id: 'chat-attachments',
        modal: ChatAttachmentsModal,
    },
    {
        id: 'chat-members',
        modal: ChatMembersModal,
    },

    /** Task modals */
    {
        id: 'manage-task-search',
        modal: ManageTaskSearchModal,
    },

    /** Company modals */
    {
        id: 'company-about-text',
        modal: CompanyAboutTextModal,
    },
    {
        id: 'company-details',
        modal: CompanyDetailsModal,
    },

    /** Course modals */
    {
        id: 'manage-course-create',
        modal: ManageCourseCreateModal,
    },
    {
        id: 'course-pricing-plan',
        modal: CoursePricingPlanModal,
    },
    {
        id: 'course-filter',
        modal: CourseFiltersModal,
    },
    {
        id: 'course-share',
        modal: CourseShareModal,
    },
    {
        id: 'course-not-participant',
        modal: CourseNotParticipantModal,
    },
    {
        id: 'manage-course-discount',
        modal: ManageCourseDiscountModal,
    },

    /** Ege modals */
    {
        id: 'ege-subject-dates',
        modal: EgeSubjectDatesModal,
    },
    {
        id: 'ege-subject-info',
        modal: EgeSubjectInfoModal,
    },

    /** Education modals */
    {
        id: 'story',
        modal: StoryModal,
    },

    /** Page modals */
    {
        id: 'context',
        modal: PageContextModal,
    },

    /** Partner modals */
    {
        id: 'short-form-become-partner',
        modal: ShortFormBecomePartnerModal,
    },

    /** Profile modals */
    {
        id: 'profile-friends',
        modal: ProfileFriendsModal,
    },
    {
        id: 'profile-set-status',
        modal: ProfileSetStatusModal,
    },
    {
        id: 'profile-wallet-deposit',
        modal: ProfileWalletDepositModal,
    },
    /** Tournaments modals */
    {
        id: 'tournament-card-info',
        modal: TournamentCardInfoModal,
    },
    {
        id: 'tournament-subject-select',
        modal: TournamentSubjectSelectModal,
    },
    {
        id: 'tournament-filters',
        modal: TournamentFiltersModal,
    },
    {
        id: 'tournament-create',
        modal: TournamentCreateModal,
    },
    {
        id: 'tournament-share',
        modal: TournamentShareModal,
    },
    {
        id: 'tournament-blitz-select',
        modal: TournamentBlitzSelectModal,
    },
    {
        id: 'tournament-invite',
        modal: TournamentInviteModal,
    },
    {
        id: 'tournament-quick-actions',
        modal: TournamentQuickActionsModal,
    },
    {
        id: 'tournament-members',
        modal: TournamentMembersModal,
    },
    {
        id: 'tournament-friends',
        modal: TournamentFriendsModal,
    },
    {
        id: 'tournament-participants-progress',
        modal: TournamentParticipantsProgressModal,
    },

    /** Manage modals */
    {
        id: 'manage-seller-info',
        modal: ManageSellerInfoModal,
    },
    {
        id: 'manage-seller-request',
        modal: ManageSellerRequestModal,
    },
    {
        id: 'manage-seller-balance-operation',
        modal: ManageSellerBalanceOperationModal,
    },
    {
        id: 'manage-seller-payout-create',
        modal: ManageSellerPayoutCreateModal,
    },
    {
        id: 'manage-school-acquiring-connect',
        modal: ManageSchoolAcquiringConnectModal,
    },
    {
        id: 'manage-school-disciplines',
        modal: ManageSchoolDisciplinesModal,
    },
    {
        id: 'manage-school-legal-doc',
        modal: ManageSchoolLegalDocModal,
    },
    {
        id: 'manage-user-create',
        modal: ManageUserCreateModal,
    },

    /** User modals */
    {
        id: 'user-menu',
        modal: UserMenuModal,
    },
    {
        id: 'user-main-info',
        modal: UserMainInfoModal,
    },
    {
        id: 'user-invite',
        modal: UserInviteModal,
    },
    {
        id: 'user-password-change',
        modal: PasswordChangeModal,
    },
    {
        id: 'user-two-factor-set',
        modal: TwoFactorSetModal,
    },

    /** Utils modals */
    {
        id: 'available-in-app',
        modal: AvailableInAppModal,
    },
    {
        id: 'confirm-modal',
        modal: ConfirmationModal,
    },
    {
        id: 'photo-editor',
        modal: PhotoEditorModal,
    },
    {
        id: 'dialog-modal',
        modal: DialogModal,
    },
    {
        id: 'select-modal',
        modal: SelectMimicryModal,
    },
] as const;

