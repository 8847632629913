/**
 * ManageCourseDiscountsPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';

import { SortDirection } from '@/codegen/graphql';

import { defaultDiscountsFilter, dtoFilterDiscountsTransformer } from './filter';


class ManageCourseDiscountsPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: { skip: 0, take: 10 },
        filter: dtoFilterDiscountsTransformer(defaultDiscountsFilter),
        sort: { id: SortDirection.Desc },
    };

}

const ManageCourseDiscountsPageStore = new ManageCourseDiscountsPage();


export { observer, ManageCourseDiscountsPageStore };
