/**
 * UserInfoItem
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Dispatch, Fragment, SetStateAction } from 'react';

import { FieldArray, FormikErrors, FormikHandlers, FormikHelpers, FormikTouched, FormikValues } from 'formik';

import { Field, If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { TaskBuilderFormValues, useSchoolUser } from '@/hooks/apollo';

import { Button, FormItem, Input, Separator, SimpleCell } from '@exode.ru/vkui';
import { Icon24AddSquareOutline, Icon24ChevronDown, Icon24ChevronUp, Icon24DeleteOutline } from '@vkontakte/icons';

import { PhoneInput } from '@/components/Atoms/PhoneInput';
import { ConfirmIconButton } from '@/components/Atoms/ConfirmIconButton';


interface Props {
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    showUserInfo: Record<number, boolean>;
    values: ReturnType<typeof useSchoolUser>['initialValuesForCreate']['users'];
    formikHandlers: Pick<FormikHandlers, 'handleBlur' | 'handleChange'>;
    setFieldValue: FormikHelpers<TaskBuilderFormValues>['setFieldValue'];
    setShowUserInfo: Dispatch<SetStateAction<{ [key: number]: boolean; }>>;
}


const UserInfoItem = (props: Props) => {

    const {
        values,
        errors,
        touched,
        showUserInfo,
        setFieldValue,
        setShowUserInfo,
        formikHandlers: {
            handleBlur,
            handleChange,
        },
    } = props;

    const { t } = useI18n('pages.Manage.School.Users.views');

    const toggleUserInfo = (index: number) => {
        setShowUserInfo(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <FieldArray name="users.accounts">
            {({ push, remove }) => (
                <>
                    {values?.accounts.map((account, index: number) => {
                        const { type, email, phone } = account;

                        const isEmailMode = type === 'email';

                        return (
                            <Fragment key={index}>
                                <SimpleCell hasHover={false}
                                            hasActive={false}
                                            onClick={() => toggleUserInfo(index)}
                                            className="bg-hover thin-border vk-rounded cursor-pointer mt-5"
                                            after={(
                                                <div className="flex items-center gap-2">
                                                    {showUserInfo[index] ? <Icon24ChevronUp/> : <Icon24ChevronDown/>}

                                                    <If is={values.accounts?.length > 1}>
                                                        <ConfirmIconButton stopPropagation
                                                                           text={t('deleteUser')}
                                                                           data-test="user.delete"
                                                                           className="rounded-full"
                                                                           callback={() => remove(index)}
                                                                           initialIcon={(
                                                                               <Icon24DeleteOutline fill="var(--accent)"/>
                                                                           )}
                                                                           confirmIcon={(
                                                                               <Icon24DeleteOutline fill="var(--destructive)"/>
                                                                           )}/>
                                                    </If>
                                                </div>
                                            )}>
                                    {t('userIndex', { index: index + 1 })}
                                </SimpleCell>

                                <If is={showUserInfo[index]}>
                                    <FormItem className="p-0 mt-5" top={(
                                        <div className="flex justify-between">
                                            {isEmailMode ? t('userEmail') : t('userPhone')}

                                            <span className="text-accent cursor-pointer"
                                                  data-test="user-create.phone-email-toggler"
                                                  onClick={() => {
                                                      setFieldValue(
                                                          `users.accounts.${index}.type`,
                                                          isEmailMode ? 'phone' : 'email',
                                                      );
                                                  }}>
                                                {isEmailMode ? t('switchToPhone') : t('switchToEmail')}
                                            </span>
                                        </div>
                                    )} status={Field.status(
                                        errors,
                                        touched,
                                        isEmailMode
                                            ? `users.accounts.${index}.email`
                                            : `users.accounts.${index}.phone`,
                                    )} bottom={Field.message(
                                        errors,
                                        touched,
                                        isEmailMode
                                            ? `users.accounts.${index}.email`
                                            : `users.accounts.${index}.phone`,
                                    )}>
                                        <If is={isEmailMode}>
                                            <Input value={email}
                                                   onBlur={handleBlur}
                                                   data-test="user-create.email"
                                                   placeholder={t('enterUserEmail')}
                                                   name={`users.accounts.${index}.email`}
                                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                        </If>

                                        <If is={type === 'phone'}>
                                            <PhoneInput required
                                                        value={phone}
                                                        onBlur={handleBlur}
                                                        inputClassName="w-full p-4"
                                                        data-test="user-create.phone"
                                                        placeholder={t('enterUserPhone')}
                                                        name={`users.accounts.${index}.phone`}
                                                        onChange={(phone) => {
                                                            setFieldValue(`users.accounts.${index}.phone`, phone);
                                                        }}/>
                                        </If>
                                    </FormItem>
                                </If>

                                <If is={index < (values?.accounts.length || 0) - 1}>
                                    <Separator className="mt-5 mb-2"/>
                                </If>
                            </Fragment>
                        );
                    })}

                    <div>
                        <Button stretched
                                size="l"
                                type="button"
                                mode="secondary"
                                className="mt-5"
                                data-test="user.add"
                                before={<Icon24AddSquareOutline/>}
                                disabled={values.accounts.length >= 30}
                                onClick={() => {
                                    push({ type: 'email' });

                                    setShowUserInfo(prevState => ({
                                        ...prevState,
                                        [values.accounts?.length]: true,
                                    }));
                                }}>
                            {t('anotherStudent')}
                        </Button>
                    </div>
                </>
            )}
        </FieldArray>
    );
};


export { UserInfoItem };
