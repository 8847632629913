/**
 * DialogsListView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { MutableRefObject } from 'react';

import { ObservableQuery, useApolloClient } from '@apollo/client';

import { observer, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import {
    ChatFindManyDocument,
    ChatFindManyQuery,
    ChatFindManyQueryResult,
    ChatFindManyQueryVariables,
} from '@/codegen/graphql';

import { Spinner } from '@exode.ru/vkui';
import { GqlResult } from '@/types/graphql';
import { InfiniteScroll } from '@/helpers/ui/Scroller';

import { ChatCellItem } from '../items/ChatCellItem';

import { useChatListFilter } from '../filter';


interface Props {
    activeChatId: number | undefined;
    fetchMore: ObservableQuery<ChatFindManyQuery>['fetchMore'];
    items: GqlResult<ChatFindManyQueryResult>['chatFindMany']['items'];
    cursor: GqlResult<ChatFindManyQueryResult>['chatFindMany']['cursor'];
    parentRef: MutableRefObject<HTMLDivElement | null>;
    onChatCellClick: (chatId: number) => void;
    variables?: ChatFindManyQueryVariables;
    previousVariables?: ChatFindManyQueryVariables;
    isChatCellRounded?: boolean;
}


const DialogsListView = observer((props: Props) => {

    const { cache } = useApolloClient();

    const {
        activeChatId,
        items,
        cursor,
        fetchMore,
        isChatCellRounded = false,
        variables,
        previousVariables,
        onChatCellClick,
        parentRef,
    } = props;

    const { dtoFilter } = useChatListFilter();
    const { list, sort } = useStore(ChatDialogsPageStore);

    const fetchMoreVariables = variables
        ? {
            ...variables,
            cursor: { before: cursor.before },
        }
        : {
            filter: dtoFilter,
            list: { ...list.chats },
            sort: { ...sort.chats },
            cursor: { before: cursor.before },
        };

    return (
        <InfiniteScroll initialLoad={true}
                        useWindow={false}
                        threshold={1500}
                        hasMore={cursor.hasPrev}
                        getScrollParent={() => parentRef?.current}
                        loader={<Spinner key={0} className="mb-2.5 h-[60px] loader"/>}
                        loadMore={async () => {
                            const { data: { chatFindMany } } = await fetchMore({
                                variables: fetchMoreVariables,
                            });

                            const cached = cache.readQuery<ChatFindManyQuery>({
                                variables: previousVariables,
                                query: ChatFindManyDocument,
                            });

                            if (chatFindMany) {
                                cache.writeQuery<ChatFindManyQuery>({
                                    variables: previousVariables,
                                    query: ChatFindManyDocument,
                                    data: {
                                        chatFindMany: {
                                            __typename: 'ListChatOutput',
                                            cursor: {
                                                __typename: 'CursorOutput',
                                                ...chatFindMany.cursor,
                                            },
                                            count: chatFindMany.count || 0,
                                            items: [
                                                ...(cached?.chatFindMany.items ?? []),
                                                ...(chatFindMany?.items ?? []),
                                            ],
                                        },
                                    },
                                });
                            }
                        }}>
            {items?.map((chat) => (
                <ChatCellItem key={chat.id}
                              chat={chat}
                              isRounded={isChatCellRounded}
                              onChatCellClick={onChatCellClick}
                              isActive={activeChatId === chat.id}/>
            ))}
        </InfiniteScroll>
    );
});


export { DialogsListView };
