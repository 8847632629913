/**
 * Field util
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { FormEventHandler } from 'react';

import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';


const fieldStatus = <T, >(
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    name: keyof T,
    path?: string,
) => {
    const key = [ name, path ].filter((e) => e).join('.');

    return _.get(touched, key) && _.get(errors, key) ? 'error' : 'default';
};

const fieldMessage = <T, >(
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    name: keyof T,
    placeholder = ' ',
    path?: string,
) => {
    const key = [ name, path ].filter((e) => e).join('.');

    return _.get(touched, key) && _.get(errors, key)
        ? _.get(errors, key)?.toString()
        : (placeholder === ' ' ? ' ' : placeholder);
};

const ignoreSpaceOnChange = (
    handleChange: FormikHandlers['handleChange'],
    options: {
        trim?: boolean;
    } = {},
) => {
    return ((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value?.trim() === '') {
            e.target.value = '';
        }

        if (e.target.value) {
            let value = e.target.value
                .replace(/ {2}/g, ' ')
                .replace(/\n{3,}/g, '\n\n');

            if (options.trim) {
                value = value?.trim();
            }

            e.target.value = value;
        }

        return handleChange(e);
    }) as FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};


export const Field = {
    status: fieldStatus,
    message: fieldMessage,
    transform: {
        ignoreSpaceOnChange,
    },
};
