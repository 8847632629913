/**
 * UserInviteView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { APP_URL } from '@/root/src/env';
import { observer, UserAuthStore } from '@/store/user/auth';

import { CopyToClipboard } from '@/components/Atoms/CopyToClipboard';
import { SocialShareButtons } from '@/components/Atoms/SocialShareButtons';


const UserInviteView = observer(() => {

    const referralLink = `${APP_URL}/referral/${UserAuthStore.user?.uuid}`;

    return (
        <>
            <CopyToClipboard link={referralLink} className="my-6"/>
            <SocialShareButtons link={referralLink}/>
        </>
    );
});


export { UserInviteView };
