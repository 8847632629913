/**
 * DesktopHeaderMenu
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { useI18n } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { RoutePathType } from '@/router/paths';
import { Router } from '@/services/Utils/Router';
import { Link, Platform, Unauth } from '@/cutils';

import { shouldDrawerBeShown } from '@/components/Desktop/Manage/ManageServicesList';

import { HeaderItemPart } from './parts/HeaderItemPart';


interface Props {
    mobileTitle: string;
}


const DesktopHeaderMenu = observer((props: Props) => {

    const { mobileTitle } = props;

    const { route: { pageId } } = useLocation();

    const { t } = useI18n('components.Desktop.Navigation.DesktopHeaderMenu');

    const firstManageItem = Router.getFirstManageDrawerItem();
    const isManageMode = shouldDrawerBeShown() && firstManageItem?.path?.id;

    if (!ConfigStore.isDesktopOnly) {
        return (
            <>{mobileTitle}</>
        );
    }

    const items: Array<{
        id: RoutePathType;
        title: string;
        extraPaths?: RoutePathType[];
    }> = [
        {
            id: isManageMode && firstManageItem?.path?.id
                ? firstManageItem.path.id
                : '/education',
            title: isManageMode ? t('controlPanel') : t('education'),
        },
        {
            id: '/catalog',
            title: t('catalog'),
            extraPaths: [
                '/',
                '/catalog/:page([0-9]+)',
            ],
        },
        {
            id: '/support',
            title: 'FAQ',
        },
        {
            id: '/manage/my-schools',
            title: t('mySchools'),
        },
        {
            id: '/school/create',
            title: t('createSchool'),
        },
    ];

    return (
        <div className="flex h-[var(--panelheader\_height)] flex-1 gap-2">
            {items.filter(({ id }) => Router.filterByPageId(id))
                .map(({ id, title, extraPaths }) => (
                    <Link pushPage={{ id }}>
                        <HeaderItemPart title={title} active={id === pageId || extraPaths?.includes(pageId)}/>
                    </Link>
                ))
            }

            <Platform.Market>
                <Unauth>
                    <HeaderItemPart hasHover={false}
                                    title={t('createCourse')}
                                    textClassName="text-marker"
                                    onClick={() => {
                                        Router.replaceToModalAfterLogin(
                                            '/education',
                                            'short-form-become-partner',
                                        );
                                    }}/>
                </Unauth>
            </Platform.Market>
        </div>
    );
});


export { DesktopHeaderMenu };
