/**
 * FileUtil
 *
 * @author: exode <hello@exode.ru>
 */

import { FileTypeByMime } from './enums';


export type FileImageDimensions = {
    width: number;
    height: number;
}


class FileUtil {

    /**
     * Form bytes
     * @param {number} bytes
     * @returns {string}
     */
    static getFileSize(bytes: number) {
        let size = Math.abs(bytes);

        if (Number.isNaN(size) || size === 0) {
            return '0 B';
        }

        const units = [ 'B', 'KB', 'MB', 'GB', 'TB' ];
        const floor = Math.floor(Math.log10(size) / 3);

        const quotient = floor < units.length ? floor : units.length - 1;
        size /= (1000 ** quotient);

        return `${Math.round(+size.toFixed(2))} ${units[quotient]}`;
    }

    /**
     * Get video duration by file
     * @param {File} file
     * @returns {Promise<unknown>}
     */
    static getVideoDurationByFile(file: File) {
        if (typeof document === 'undefined') {
            return 0;
        }

        const video = document.createElement('video');

        video.preload = 'metadata';

        const result = new Promise((resolve) => {
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                resolve(video.duration);

                video.remove();
            };
        });

        video.src = window.URL.createObjectURL(file);

        return result;
    }

    /**
     * Get image dimension
     * @param {File} file
     * @returns {Promise<unknown>}
     */
    static getImageDimensions(file: File): Promise<FileImageDimensions> {
        return new Promise((resolve, reject) => {
            const url = window.URL || window.webkitURL;

            const image = new Image();
            const objectUrl = url.createObjectURL(file);

            image.onload = () => {
                url.revokeObjectURL(objectUrl);

                resolve({
                    width: image.width,
                    height: image.height,
                });
            };

            image.src = objectUrl;
            image.onerror = (error) => reject(error);
        });
    }

    /**
     * Get file url extension
     * @param {string} url
     * @returns {string}
     */
    static getFileUrlExtension(url: string) {
        return url.split('/')?.pop()?.split('.')?.pop()?.trim()?.split('?')?.[0];
    }

    /**
     * Get the file type by mime type
     * @param {string} mimeType
     */
    static getFileTypeByMimeType(mimeType: string) {
        if (mimeType.startsWith('image/')) {
            return FileTypeByMime.Image;
        }

        if (mimeType.startsWith('video/')) {
            return FileTypeByMime.Video;
        }

        return FileTypeByMime.File;
    }

    /**
     * Get the file by base64
     * @param {string} base64Data
     */
    static getFileByBase64(base64Data: string) {
        const base64 = base64Data.split(',')[1];
        const mimeType = base64Data.split(';')[0].split(':')[1];

        const byteArray = Buffer.from(
            base64.replace(/^[\w;:\/]+base64,/g, ''),
            'base64',
        );

        const blob = new Blob([ byteArray ], { type: mimeType });

        return new File([ blob ], '', { type: mimeType });
    }

    /**
     * Create new file list by new file
     * @param {File} file
     * @param {string} fileName
     */
    static createFileList(
        file: File,
        fileName: string,
    ) {
        const newFile = new File([ file ], fileName, { type: file.type });

        const dt = new DataTransfer();
        dt.items.add(newFile);

        return dt.files;
    }

}


export { FileUtil };
