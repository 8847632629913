/**
 * PhoneInput
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { defaultCountries, PhoneInput as Phone, PhoneInputProps } from 'react-international-phone';

import { Container } from './PhoneInput.styled';


interface Props extends PhoneInputProps {

}


const PhoneInput = (props: Props) => {

    const { onFocus, onBlur } = props;

    const [ hasFocus, setHasFocus ] = useState(false);

    return (
        <Container hasFocus={hasFocus} height={props.style?.height} className="thin-border vk-rounded">
            <Phone {...props} defaultCountry="uz"
                   countries={defaultCountries.filter(([ __, code ]) => code === 'uz')}
                   onFocus={(e) => {
                       onFocus?.(e);
                       setHasFocus(true);
                   }}
                   onBlur={(e) => {
                       onBlur?.(e);
                       setHasFocus(false);
                   }}/>
        </Container>
    );
};


export { PhoneInput };
