/**
 * CopyToClipboard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { If } from '@/cutils';
import { useCopy, useI18n } from '@/hooks/core';

import { Icon28CopyOutline } from '@vkontakte/icons';
import { SimpleCell, SimpleCellProps } from '@exode.ru/vkui';


interface Props extends SimpleCellProps {
    link: string;
    message?: string;
    buttonText?: string;
    vkComponent?: ReactElement;
    className?: string;
}


const CopyToClipboard = (props: Props) => {

    const { t } = useI18n('components.Atoms.CopyToClipboard');

    const {
        link,
        after,
        vkComponent,
        buttonText,
        className,
        message = t('linkCopied'),
        ...rest
    } = props;

    const { copy, ref, onCopy } = useCopy();

    const onClickCopy = () => copy(link)?.then(() => onCopy(message));

    return (
        <>
            <If is={!vkComponent}>
                <SimpleCell getRootRef={ref} onClick={onClickCopy} className={[
                    className,
                    'vk-rounded bg-hover thin-border-border',
                ].join(' ')} after={(
                    <div className="flex gap-2">
                        {after}

                        <Icon28CopyOutline className="text-subhead"/>
                    </div>
                )} {...rest}>
                    <span style={{ userSelect: 'none' }}>{buttonText || link}</span>
                </SimpleCell>
            </If>

            <If is={!!vkComponent}>
                {vkComponent
                    ? React.cloneElement(vkComponent, { onClick: onClickCopy, getRootRef: ref })
                    : <></>
                }
            </If>
        </>
    );
};


export { CopyToClipboard };
