/**
 * UseArticleBlockManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { apolloClient } from '@/api/graphql';

import {
    ArticleFindOneDocument,
    ArticleFindOneQuery,
    CreateElementContentInput,
    UpdateBlockOrderArticleInput,
    UpdateElementContentInput,
    useArticleBlockManageCreateMutation,
    useArticleBlockManageDeleteMutation,
    useArticleBlockManageRecoverMutation,
    useArticleBlockManageUpdateMutation,
    useArticleBlockManageUpdateOrderMutation,
} from '@/codegen/graphql';

import { ContentElementItem } from '@/components/ContentElement';


interface Props {
    articleId: number,
}


export const useArticleBlockManage = (props: Props) => {

    const { articleId } = props;

    const getVariables = () => ({
        articleId,
    });

    const blocksToRef = (blocks: ContentElementItem[]) => {
        return blocks.map(({ id }) => ({ __ref: `ContentElementEntity:${id}` }));
    };

    const getExistArticleBlocks = () => {
        const { cache } = apolloClient;

        const variables = getVariables();

        const { articleFindOne } = cache.readQuery<ArticleFindOneQuery>({
            variables,
            query: ArticleFindOneDocument,
        }) || {};

        return articleFindOne?.blocks;
    };

    const [ _createBlock, {
        loading: createBlockLoading,
        error: createBlockError,
    } ] = useArticleBlockManageCreateMutation({
        onError: error => console.error(error),
        update: (cache, { data }) => {
            const existBlocks = getExistArticleBlocks();

            if (!data || !existBlocks) {
                return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
            }

            cache.modify({
                id: `ArticleEntity:${articleId}`,
                fields: {
                    blocks: () => blocksToRef([
                        ...existBlocks,
                        data?.articleBlockManageCreate,
                    ]),
                },
            });
        },
    });

    const createBlock = (
        contentElement: CreateElementContentInput,
    ) => {
        return _createBlock({
            variables: {
                articleId,
                contentElement,
            },
        });
    };

    const [ _updateBlock, {
        loading: updateBlockLoading,
        error: updateBlockError,
    } ] = useArticleBlockManageUpdateMutation({
        onError: error => console.error(error),
    });

    const updateBlock = (
        contentElementId: number,
        contentElement: UpdateElementContentInput,
    ) => {
        return _updateBlock({
            variables: {
                articleId,
                contentElementId,
                contentElement,
            },
        });
    };

    const [ _deleteBlock, {
        loading: deleteBlockLoading,
        error: deleteBlockError,
    } ] = useArticleBlockManageDeleteMutation({
        onError: error => console.error(error),
    });

    const deleteBlock = (
        contentElementId: number,
    ) => {
        return _deleteBlock({
            variables: {
                articleId,
                contentElementId,
            },
            update: (cache, { data }) => {
                const existBlocks = getExistArticleBlocks();

                if (!data && !existBlocks) {
                    return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
                }

                cache.modify({
                    id: `ArticleEntity:${articleId}`,
                    fields: {
                        blocks: () => blocksToRef(existBlocks!.filter(({ id }) => id !== contentElementId)),
                    },
                });
            },
        });
    };

    const [ _recoverBlock, {
        loading: recoverBlockLoading,
        error: recoverBlockError,
    } ] = useArticleBlockManageRecoverMutation({
        onError: error => console.error(error),
    });

    const recoverBlock = (
        contentElementId: number,
        deletedBlock?: ContentElementItem,
    ) => {
        const recoverToCache = (block: ContentElementItem) => {
            const { cache } = apolloClient;

            const existBlocks = getExistArticleBlocks();

            if (!block || !existBlocks) {
                return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
            }

            const orderedBlocks = _.orderBy([
                ...existBlocks,
                block,
            ], 'order');

            cache.modify({
                id: `ArticleEntity:${articleId}`,
                fields: {
                    blocks: () => blocksToRef(orderedBlocks),
                },
            });
        };

        if (deletedBlock) {
            recoverToCache(deletedBlock);
        }

        return _recoverBlock({
            variables: {
                articleId,
                contentElementId,
            },
            update: (__, { data }) => {
                if (data && !deletedBlock) {
                    recoverToCache(data?.articleBlockManageRecover);
                }
            },
        });
    };

    const [ _updateBlockOrder, {
        loading: updateBlockOrderLoading,
        error: updateBlockOrderError,
    } ] = useArticleBlockManageUpdateOrderMutation({
        onError: error => console.error(error),
    });

    const updateBlockOrderInCache = (orderBlocks: ContentElementItem[]) => {
        const { cache } = apolloClient;

        const existBlocks = getExistArticleBlocks();

        if (!orderBlocks || !existBlocks) {
            return console.warn('[Cache]: cachedArticles отсутствуют в кэше');
        }

        const orderedBlocks = _.orderBy(existBlocks.map((block) => {
            const orderBlock = _.find(orderBlocks, { id: block.id });

            return orderBlock
                ? { ...block, order: orderBlock.order }
                : block;
        }), 'order');

        cache.modify({
            id: `ArticleEntity:${articleId}`,
            fields: { blocks: () => blocksToRef(orderedBlocks) },
        });
    };

    const updateBlockOrder = (
        blockOrder: UpdateBlockOrderArticleInput,
    ) => {
        return _updateBlockOrder({
            variables: {
                articleId,
                blockOrder,
            },
        });
    };

    return {
        createBlock,
        createBlockError,
        createBlockLoading,
        updateBlock,
        updateBlockError,
        updateBlockLoading,
        deleteBlock,
        deleteBlockError,
        deleteBlockLoading,
        recoverBlock,
        recoverBlockError,
        recoverBlockLoading,
        updateBlockOrder,
        updateBlockOrderError,
        updateBlockOrderLoading,
        updateBlockOrderInCache,
    };
};
