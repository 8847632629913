/**
 * LessonAfterIconPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { lessonProgressStatusIcon } from '@/types/course';
import { CourseProgressLessonStatus } from '@/codegen/graphql';

import { Spinner } from '@exode.ru/vkui';
import { Icon24SkipToAction } from '@vkontakte/icons';

import { CourseService } from '@/services/Course/Course';

import { LessonCardProps } from '@/components/Course/LessonCard';


interface Props {
    course: LessonCardProps['course'];
    lesson: Pick<LessonCardProps['lesson'], 'myProgressStatus' | 'previous'>;
    isLoading?: boolean;
}


const LessonAfterIconPart = (props: Props) => {

    const {
        course,
        lesson,
        isLoading,
        lesson: { myProgressStatus },
    } = props;

    const featureDisabledIsAvailable = CourseService.canStartNextFeatureDisabled(
        course,
        lesson.previous,
        lesson.myProgressStatus,
    );

    return (
        <div className="min-w-[24px] min-h-[24px] relative">
            {myProgressStatus && lessonProgressStatusIcon[myProgressStatus]
                ? (
                    featureDisabledIsAvailable
                        ? (
                            isLoading
                                ? <Spinner size="regular"/>
                                : CourseService.nextLessonStartManually(course, course.currentLesson)
                                    ? lessonProgressStatusIcon[CourseProgressLessonStatus.FeatureDisabled]
                                    : <Icon24SkipToAction fill="var(--accent)"/>

                        )
                        : lessonProgressStatusIcon[myProgressStatus]
                )
                : lessonProgressStatusIcon[CourseProgressLessonStatus.NotParticipant]}
        </div>
    );
};


export { LessonAfterIconPart };
