/**
 * UpdateProfileInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsNotEmpty, IsOptional, Matches, MaxLength, MinLength } from 'class-validator';

import { regex } from '@/utils';
import { UserSexType } from '@/codegen/graphql';

import { isMinMax, isNotEmpty } from '../constants';


export class UpdateProfileInput {

    @Matches(regex.alphabetAndSpace, {
        message: () => 'Используйте только буквы',
    })
    @MinLength(2, { message: isMinMax(2, 'Имя', 'min', 'должно') })
    @MaxLength(15, { message: isMinMax(15, 'Имя', 'max', 'должно') })
    @IsNotEmpty({ message: isNotEmpty('Имя') })
    readonly firstName: string;

    @Matches(regex.alphabetAndSpace, {
        message: () => 'Используйте только буквы',
    })
    @MinLength(2, { message: isMinMax(2, 'Фамилия', 'min', 'должна') })
    @MaxLength(15, { message: isMinMax(15, 'Фамилия', 'max', 'должна') })
    @IsNotEmpty({ message: isNotEmpty('Фамилия', 'пустой') })
    readonly lastName: string;

    @IsOptional()
    readonly birthDay: string;

    @IsOptional()
    readonly birthMonth: string;

    @IsOptional()
    readonly birthYear: string;

    @IsOptional()
    readonly bdate: string;

    @IsNotEmpty({ message: isNotEmpty('Домен') })
    readonly domain: string;

    @IsOptional()
    readonly sex: UserSexType;

}
