/**
 * DesktopNavigationContextMenuDrawer component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { KeyboardEvent, MouseEvent, useState } from 'react';

import { Portal, Responsive } from '@/cutils';

import { DrawerContainer } from '@/components/Desktop/Navigation';
import { BurgerMenuPart } from '@/components/Desktop/Navigation/MenuDrawer/parts/BurgerMenu';


const DesktopNavigationContextMenuDrawer = () => {

    const [ open, setOpen ] = useState(false);

    const toggleDrawer = (isOpen: boolean) => ((event: KeyboardEvent | MouseEvent) => {
        const { key } = event as KeyboardEvent;

        if (event.type === 'keydown' && [ 'Tab', 'Shift' ].includes(key)) {
            return;
        }

        setOpen(isOpen);
    });

    return (
        <div className="flex items-center vkui__root">
            <Responsive.Width forHiddenContext>
                <BurgerMenuPart onClick={toggleDrawer(true)}/>
            </Responsive.Width>

            <DrawerContainer open={open}
                             anchor="right"
                             className="drawer-container-p vkui__root"
                             onClick={toggleDrawer(false)}
                             onKeyDown={toggleDrawer(false)}
                             onClose={toggleDrawer(false)}>
                <Portal id="menu:context" renderer/>
            </DrawerContainer>
        </div>
    );
};


export { DesktopNavigationContextMenuDrawer };
