/**
 * UseLessonBlockManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { apolloClient } from '@/api/graphql';

import {
    CreateElementContentInput,
    GetLessonDocument,
    GetLessonQuery,
    UpdateElementContentInput,
    UpdateLessonBlockOrderCourseInput,
    useCourseLessonBlockManageCreateMutation,
    useCourseLessonBlockManageDeleteMutation,
    useCourseLessonBlockManageRecoverMutation,
    useCourseLessonBlockManageUpdateMutation,
    useCourseLessonBlockManageUpdateOrderMutation,
} from '@/codegen/graphql';

import { useCourseManage } from '@/hooks/apollo';

import { ContentElementItem } from '@/components/ContentElement';


interface Props {
    courseId: number,
    lessonId: number,
}


export const useLessonBlockManage = (props: Props) => {

    const { courseId, lessonId } = props;

    const { triggerUpdatePreviewEvent } = useCourseManage();

    const getVariables = () => ({
        courseId,
        lessonId,
    });

    const blocksToRef = (blocks: ContentElementItem[]) => {
        return blocks.map(({ id }) => ({ __ref: `ContentElementEntity:${id}` }));
    };

    const onLessonBlocksChange = () => {
        triggerUpdatePreviewEvent('course', courseId);
    };

    const getExistLessonBlocks = (): ContentElementItem[] | undefined => {
        const { cache } = apolloClient;

        const variables = getVariables();

        const { courseLessonFindOne } = cache.readQuery<GetLessonQuery>({
            variables,
            query: GetLessonDocument,
        }) || {};

        return courseLessonFindOne?.blocks;
    };

    const [ _createBlock, {
        loading: createBlockLoading,
        error: createBlockError,
    } ] = useCourseLessonBlockManageCreateMutation({
        onError: error => console.error(error),
        onCompleted: () => {
            onLessonBlocksChange();
        },
        update: (cache, { data }) => {
            const existBlocks = getExistLessonBlocks();

            if (!data || !existBlocks) {
                return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
            }

            cache.modify({
                id: `CourseLessonEntity:${lessonId}`,
                fields: {
                    blocks: () => blocksToRef([
                        ...existBlocks,
                        data?.courseLessonBlockManageCreate,
                    ]),
                },
            });
        },
    });

    const createBlock = (
        contentElement: CreateElementContentInput,
    ) => {
        return _createBlock({
            variables: {
                courseId,
                lessonId,
                contentElement,
            },
        });
    };

    const [ _updateBlock, {
        loading: updateBlockLoading,
        error: updateBlockError,
    } ] = useCourseLessonBlockManageUpdateMutation({
        onError: error => console.error(error),
        onCompleted: () => {
            onLessonBlocksChange();
        },
    });

    const updateBlock = (
        contentElementId: number,
        contentElement: UpdateElementContentInput,
    ) => {
        return _updateBlock({
            variables: {
                courseId,
                contentElementId,
                contentElement,
            },
        });
    };

    const [ _deleteBlock, {
        loading: deleteBlockLoading,
        error: deleteBlockError,
    } ] = useCourseLessonBlockManageDeleteMutation({
        onError: error => console.error(error),
        onCompleted: () => {
            onLessonBlocksChange();
        },
    });

    const deleteBlock = (
        contentElementId: number,
    ) => {
        return _deleteBlock({
            variables: {
                courseId,
                contentElementId,
            },
            update: (cache, { data }) => {
                const existBlocks = getExistLessonBlocks();

                if (!data || !existBlocks) {
                    return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
                }

                cache.modify({
                    id: `CourseLessonEntity:${lessonId}`,
                    fields: {
                        blocks: () => blocksToRef(existBlocks.filter(({ id }) => id !== contentElementId)),
                    },
                });
            },
        });
    };

    const [ _recoverBlock, {
        loading: recoverBlockLoading,
        error: recoverBlockError,
    } ] = useCourseLessonBlockManageRecoverMutation({
        onError: error => console.error(error),
        onCompleted: () => {
            onLessonBlocksChange();
        },
    });

    const recoverBlock = (
        contentElementId: number,
        deletedBlock?: ContentElementItem,
    ) => {
        const recoverToCache = (block: ContentElementItem) => {
            const { cache } = apolloClient;

            const existBlocks = getExistLessonBlocks();

            if (!block || !existBlocks) {
                return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
            }

            const orderedBlocks = _.orderBy([
                ...existBlocks,
                block,
            ], 'order');

            cache.modify({
                id: `CourseLessonEntity:${lessonId}`,
                fields: {
                    blocks: () => blocksToRef(orderedBlocks),
                },
            });
        };

        if (deletedBlock) {
            recoverToCache(deletedBlock);
        }

        return _recoverBlock({
            variables: {
                courseId,
                contentElementId,
            },
            update: (__, { data }) => {
                if (data && !deletedBlock) {
                    recoverToCache(data.courseLessonBlockManageRecover);
                }
            },
        });
    };

    const [ _updateBlockOrder, {
        loading: updateBlockOrderLoading,
        error: updateBlockOrderError,
    } ] = useCourseLessonBlockManageUpdateOrderMutation({
        onError: error => console.error(error),
        onCompleted: () => {
            onLessonBlocksChange();
        },
    });

    const updateBlockOrderInCache = (orderBlocks: ContentElementItem[]) => {
        const { cache } = apolloClient;

        const existBlocks = getExistLessonBlocks();

        if (!orderBlocks || !existBlocks) {
            return console.warn('[Cache]: cachedBlocks отсутствует в кэше');
        }

        const orderedBlocks = _.orderBy(existBlocks.map((block) => {
            const orderBlock = _.find(orderBlocks, { id: block.id });

            return orderBlock
                ? { ...block, order: orderBlock.order }
                : block;
        }), 'order');

        cache.modify({
            id: `CourseLessonEntity:${lessonId}`,
            fields: { blocks: () => blocksToRef(orderedBlocks) },
        });
    };

    const updateBlockOrder = (
        blockOrder: UpdateLessonBlockOrderCourseInput,
    ) => {
        return _updateBlockOrder({
            variables: {
                courseId,
                lessonId,
                blockOrder,
            },
        });
    };

    return {
        createBlock,
        createBlockError,
        createBlockLoading,
        updateBlock,
        updateBlockError,
        updateBlockLoading,
        deleteBlock,
        deleteBlockError,
        deleteBlockLoading,
        recoverBlock,
        recoverBlockError,
        recoverBlockLoading,
        updateBlockOrder,
        updateBlockOrderError,
        updateBlockOrderLoading,
        updateBlockOrderInCache,
    };
};
